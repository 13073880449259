<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t("courierSet.serviceInfo").toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <check-field
              v-model="isTestEnv"
              :label="$t('courierSet.isTestEnv')"
              @input="update"
            />
          </v-col>

          <v-col>
            <check-field
              v-model="returnOfDocumentsIsUsed"
              :label="$t('courierSet.returnOfDocumentsIsUsed')"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="ediSender"
              :title="$t('courierSet.ediSender')"
              :hint="$t('courierSet.ediSenderHint')"
              rules="required"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="ediSenderPrefix"
              :title="$t('courierSet.ediSenderPrefix')"
              :hint="$t('courierSet.ediSenderPrefixHint')"
              rules="required|max:5|min:5"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="ediReceiver"
              :title=" $t('courierSet.ediReceiver')"
              rules="required"
              :filed-items="ediReceiversTestOrProd"
              @input="update"
            />
          </v-col>
          <v-col>
            <select-field
              v-model="fileType"
              :title=" $t('courierSet.shippingOrders')"
              rules="required"
              :filed-items="fileTypes"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="department"
              :title="$t('courierSet.department')"
              :hint="$t('courierSet.departmentHint')"
              rules="required"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="payerIdentifier"
              :title="$t('courierSet.payerIdentifier')"
              :hint="$t('courierSet.payerIdentifierHint')"
              rules="required"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="productType"
              :title=" $t('courierSet.productType')"
              rules="required"
              :filed-items="productTypes"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="0"
      class="mt-4 pb-3"
    >
      <v-card-title class="pb-0">
        {{ "Referencje transportowe".toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-card-subtitle class="pt-0">
          W tej sekcji można określić dodatkowe referencje do zlecenia transportowego.
        </v-card-subtitle>
        <div
          v-for="(ref, idx) in transportReferences"
          :key="ref.id"
        >
          <CodeValueMap
            v-model="transportReferences[idx]"
            :idx="idx"
            :codes="transportReferencesCodes"
            @updateReferences="updateReferences"
            @deleteReference="deleteReference"
          />
        </div>
        <v-row>
          <v-col
            cols="12"
            class="mb-5"
          >
            <v-btn
              :disabled="!readyToAddRef"
              color="primary lighten-1"
              text
              block
              @click="addSelectedReference()"
            >
              Nowa usługa dodatkowa +
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="0"
      class="mt-4 pb-3"
    >
      <v-card-title class="pb-0">
        {{ "Dodatkowe dane transportowe".toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-card-subtitle class="pt-0">
          Poniższe dane są często niezbędne do świadczenia wszystkich
          wymaganych usług klientom Raben.
        </v-card-subtitle>
        <div
          v-for="(ref, idx) in shipmentNotesServices"
          :key="ref.id"
        >
          <CodeValueMap
            v-model="shipmentNotesServices[idx]"
            :idx="idx"
            :codes="shipmentNoteCodes"
            @updateReferences="updateShipmentServ"
            @deleteReference="deleteShipmentServ"
          />
        </div>
        <v-row>
          <v-col
            cols="12"
            class="mb-5"
          >
            <v-btn
              :disabled="!readyToAddShipmentServ"
              color="primary lighten-1"
              text
              block
              @click="addSelectedShipmentServ()"
            >
              Nowa usługa dodatkowa +
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import CodeValueMap from '@/views/Speditors/Raben/Forms/CodeValueMap.vue';
import CheckField from '@/components/inputs/CheckField.vue';
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';

export default {
  components: {
    CodeValueMap, CheckField, SelectField, TextField,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },

  data: () => ({

    confSet: null,
    sendingMethod: null,
    valid: null,
    isNewInDb: false,
    id: null,
    isTestEnv: false,
    tenantId: null,
    clientId: null,
    login: null,
    password: null,
    urlAddress: null,
    certifacteThumbprint: null,
    ediSender: null,
    ediSenderPrefix: null,
    ediReceiver: null,
    fileType: null,
    department: null,
    payerIdentifier: null,
    productType: null,
    configurationName: null,
    transportReferencesHint: ' W tej sekcji można określić dodatkowe referencje do zlecenia transportowego.',
    productTypes: [
      {
        id: 'PROD01',
        name: 'PROD01 - Cargo Classic',
      },
      {
        id: 'PROD02',
        name: 'PROD02 - Cargo Premium',
      },
    ],
    fileTypes: [
      {
        id: 'NF',
        name: 'Zlecenia krajowe',
      },
      {
        id: 'IF',
        name: 'zlecenia międzynarodowe',
      },
    ],
    ediReceivers: [
      { id: 0, name: 'Raben Poland' },
      { id: 1, name: 'Fresh Logistics' },
      { id: 2, name: 'Raben Baltics' },
      { id: 3, name: 'Raben Czech' },
      { id: 4, name: 'Raben Slovakia' },
      { id: 5, name: 'Raben Germany' },
      { id: 6, name: 'Raben Hungary' },
      { id: 7, name: 'Raben Netherlands' },
      { id: 8, name: 'Raben Romania' },
      { id: 9, name: 'Raben Ukraine' },
    ],
    returnOfDocumentsIsUsed: false,
    transportReferences: null,
    shipmentNotesServices: null,
    transportReferencesNew: false,
    shipmentNotesServicesNew: false,
    transportReferencesCodes: [
      {
        id: 'customerRef',
        code: 'Referencja zlecenia transportowego/przesyłki',
      },
      {
        id: 'paymentRef',
        code: 'Dane incoterms lub obsługa dedykowana',
      },
      {
        id: 'invoiceRef',
        code: 'Referencja dokumentu faktury',
      },
    ],
    shipmentNoteCodes: [
      {
        id: 'INF',
        code: 'Dodatkowe instrukcje transportowe w postaci tekstu',
      },
      {
        id: 'NWZ',
        code: 'Numer zamówień klienta',
      },
      {
        id: 'FAK',
        code: 'Dodatkowy tekst na fakturze',
      },
      {
        id: 'PHP',
        code: 'Dodatkowa referencja transportowa (numery zamówień odbiorcy)',
      },
      {
        id: 'EML',
        code: 'Adres e-mail; wymagany format to login@server.dot',
      },
      {
        id: 'WVA',
        code: 'Dodatkowy tekst związany z odbiorem towaru',
      },
    ],
    requiredRule: [(v) => !!v || 'To pole jest wymagane!'],

  }),
  computed: {
    ediReceiversTestOrProd() {
      if (this.isTestEnv) {
        return this.ediReceivers.map((x) => `${x} Test`);
      }
      return this.ediReceivers;
    },
    readyToAddShipmentServ() {
      if (this.shipmentNotesServices?.find((x) => x.code === '' || x.value === '')) {
        return false;
      }
      return true;
    },
    readyToAddRef() {
      if (this.transportReferences?.find((x) => x.code === '' || x.value === '')) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.getConfSet();
  },
  methods: {
    deleteShipmentServ({ idx }) {
      this.shipmentNotesServices.splice(idx, 1);
      this.update();
    },
    updateShipmentServ() {
      this.update();
    },
    addSelectedShipmentServ() {
      this.shipmentNotesServices.push({
        id: uuidv4(),
        tenantId: this.tenantId,
        clientEntityState: this.shipmentNotesServicesNew ? 4 : 3,
        code: '',
        value: '',
      });
    },
    deleteReference({ idx }) {
      this.shipmentNotesServices.splice(idx, 1);
      this.update();
    },
    updateReferences() {
      this.update();
    },
    addSelectedReference() {
      this.transportReferences.push({
        id: uuidv4(),
        tenantId: this.tenantId,
        clientEntityState: this.transportReferencesNew ? 4 : 3,
        code: '',
        value: '',
      });
    },
    update() {
      this.serviceParameters = {
        parameters: {
          clientEntityState: this.isNew ? 4 : 3,
          id: this.id,
          tenantId: this.tenantId,
          // clientId: this.clientId,
          certifacteThumbprint: this.certifacteThumbprint,
          ediSender: this.ediSender,
          ediSenderPrefix: this.ediSenderPrefix,
          ediReceiver: this.ediReceiver,
          fileType: this.fileType,
          department: this.department,
          payerIdentifier: this.payerIdentifier,
          productType: this.productType,
          isFormValid: this.isFormValid,
          returnOfDocumentsIsUsed: this.returnOfDocumentsIsUsed,
          transportReferences: this.transportReferences,
          shipmentNotesServices: this.shipmentNotesServices,
        },

      };
      this.$emit('input', this.serviceParameters);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.tenantId = this.confSet ? this.confSet.tenantId : null;

      this.id = this.confSet?.id ? this.confSet?.id : null;
      this.ediSender = this.confSet ? this.confSet.ediSender : null;
      this.ediSenderPrefix = this.confSet ? this.confSet.ediSenderPrefix : null;
      this.ediReceiver = this.confSet ? this.confSet.ediReceiver : null;
      this.fileType = this.confSet ? this.confSet.fileType : null;
      this.department = this.confSet ? this.confSet.department : null;
      this.payerIdentifier = this.confSet ? this.confSet.payerIdentifier : null;
      this.productType = this.confSet ? this.confSet.productType : null;

      this.returnOfDocumentsIsUsed = this.confSet?.rabenDefaultValues
        ? this.confSet?.rabenDefaultValues.returnOfDocumentsIsUsed : false;
      this.transportReferences = this.confSet?.rabenDefaultValues
        ? this.confSet?.rabenDefaultValues?.transportReferences : [];
      this.shipmentNotesServices = this.confSet?.rabenDefaultValues
        ? this.confSet?.rabenDefaultValues?.shipmentNotesServices : [];
      this.shipmentNotesServicesNew = this.shipmentNotesServices.length === 0 ? 4 : 3;
      this.transportReferencesNew = this.transportReferencesNew.length === 0 ? 4 : 3;
      if (this.ediReceiver && this.ediReceiver.endsWith('Test')) {
        this.isTestEnv = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-select{
  padding: 0px 10px 0px 10px;
}
.col{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
